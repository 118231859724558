import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import { AUTH0_SUS_AUDIENCE, SPS_URL } from '@/common/config'
import { IShipperCompany } from '@sennder/senn-node-microfrontend-interfaces'

class SPSService extends HttpClient {
  public async getShipper(id: string): Promise<IShipperCompany> {
    return this.get(`/api/v1/shippers/${id}`, {
      headers: { 'x-org-id': id },
    })
  }
  public async getShipperChildren(id: string): Promise<IShipperCompany[]> {
    return this.get(`/api/v1/shippers/${id}/children`, {
      headers: { 'x-org-id': id },
    })
  }
}

if (!SPS_URL) {
  throw new Error('SPS_URL not found')
}

export default new SPSService({
  httpProvider,
  getAuthHeader: async () =>
    await getAuth0Header({
      audience: AUTH0_SUS_AUDIENCE,
    }),
  baseURL: SPS_URL,
})
